module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dan Stoakes | Web Developer","short_name":"Dan Stoakes | Web Developer","start_url":"/","background_color":"#007958","theme_color":"#007958","display":"standalone","icon":"src/assets/img/favicon/favicon-57x57.png","icons":[{"src":"src/assets/img/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/img/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/img/favicon/favicon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"src/assets/img/favicon/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/img/favicon/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/img/favicon/favicon-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d235d95618d87fcf82d195843a54460"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Z3LNPF6MR9"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
