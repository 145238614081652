import React, { useState } from "react";

import NavContext from "./navContext";

const NavProvider = ({ children }) => {
    const [navOpen, setNavOpen] = useState(false);

    return (
        <NavContext.Provider value={{ navOpen, setNavOpen }}>
            { children }
        </NavContext.Provider>
    );
};

export default NavProvider;